import axios from "axios";
import Cookies from "universal-cookie";
const apiUrl = process.env.REACT_APP_API_URL;
const cookies = new Cookies();

export const addUserApi = async (
  name: string,
  email: string,
  mobile: string,
  address: string,
  roleType: string
) => {
  const token = cookies.get("token");

  try {
    const response = await axios.post(
      `${apiUrl}/login/adduser`,
      {
        Name: name,
        Email: email,
        Mobile: mobile,
        Address: address,
        RoleType: Number(roleType),
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.error(error);

    throw error;
  }
};

export const fetchUsersApi = async () => {
  const token = cookies.get("token");

  try {
    const response = await axios.get(`${apiUrl}/login/getuser`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const fetchUserByIdApi = async (userId: string) => {
  const cookies = new Cookies();
  const token = cookies.get("token");

  try {
    const response = await axios.get(`${apiUrl}/login/getuserbyid/${userId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching user by ID:", error);
    return false;
  }
};

export const deleteUserApi = async (id: string) => {
  const token = cookies.get("token");

  try {
    const response = await axios.delete(`${apiUrl}/login/delete`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        id,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error deleting lang:", error);
    throw error;
  }
};

export const updateUserApi = async (
  id: string,
  name: string,
  mobile: string,
  address: string,
  roleType: string
) => {
  const token = cookies.get("token");

  try {
    const response = await axios.post(
      `${apiUrl}/login/updateuser`,
      {
        id: id,
        name: name,
        Mobile: mobile,
        Address: address,
        RoleType: Number(roleType),
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
