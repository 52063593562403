import React, {
  createContext,
  useContext,
  useReducer,
  ReactNode,
  useState,
  useEffect,
  useMemo,
} from "react";
import { getProfileApi  } from "../../utils/Api";
import Cookies from "universal-cookie";


interface User {
  token: string;
  roleType: any;
}

interface State {
  user: User | null;
}

type Action = { type: "SET_USER"; payload: User } | { type: "LOGOUT" };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "SET_USER":
      return { user: action.payload };
    case "LOGOUT":
      return { user: null };
    default:
      return state;
  }
};

interface AuthContextProps {
  user: User | null;
  login: (token: string) => void;
  logout: () => void;
  isLoading: boolean;
  error: string | null;
  successMessage: string | null;
  fetchUserDetails: (token: string) => Promise<void>; 
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

interface AuthProviderProps {
  children: ReactNode;
}

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const cookies = useMemo(() => new Cookies(), []);
  const [state, dispatch] = useReducer(reducer, { user: null });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const fetchUserDetails = async (token:string) =>{
    setIsLoading(true)
    try{
      const profile = await getProfileApi(token);
      dispatch({
        type:'SET_USER',
        payload:{token,roleType:profile?.roleType},
      });
    }
    catch(error){
      console.error("fetching No user data ",error)
    }
    setIsLoading(false)
  };

  useEffect(()=>{
    const token = cookies.get('authToken');
    if (token) {
      setIsLoading(true);
      fetchUserDetails(token);
    }
  },[cookies])

  const login = (token: string) => {
    setIsLoading(true);
    cookies.set("authToken", token, { maxAge: 86400 });
    setIsLoading(false);
    if (token) {
      window.location.reload();  
    }
     fetchUserDetails(token);
  };

  const logout = () => {
    cookies.remove("authToken", { path: "/" });
    dispatch({ type: "LOGOUT" });
    setSuccessMessage("Logged out successfully!");
    window.location.href = "/home";
  };

  return (
    <AuthContext.Provider
      value={{
        user: state.user,
        login,
        logout,
        fetchUserDetails,
        isLoading,
        error,
        successMessage,
      }}
    >
      {isLoading ? (
        <div>Loading...</div>
      ) : error ? (
        <div>{error}</div>
      ) : (
        children
      )}
    </AuthContext.Provider>
  );
};

const useAuth = (): AuthContextProps => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export { AuthProvider, useAuth };

