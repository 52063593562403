import React, { createContext, useState, ReactNode } from 'react';

interface ThemeContextType {
  isDarkMode: boolean;
  toggleTheme: () => void;
}



export const ThemeContext = createContext<ThemeContextType | undefined>(undefined);



const ThemeProvider = ({ children }: { children: ReactNode }) => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  const toggleTheme = () => {
    setIsDarkMode((prevMode) => !prevMode);
  };

  return (
    <ThemeContext.Provider value={{ isDarkMode, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;







// import React, { createContext, useState, ReactNode, useEffect } from 'react';

// interface ThemeContextType {
//   isDarkMode: boolean;
//   toggleTheme: () => void;
// }

// export const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

// const ThemeProvider = ({ children }: { children: ReactNode }) => {
//   const [isDarkMode, setIsDarkMode] = useState(() => {
//     const savedTheme = localStorage.getItem('isDarkMode');
//     return savedTheme ? JSON.parse(savedTheme) : false; 
//   });


//   const toggleTheme = () => {
//     setIsDarkMode((prevMode: any) => {
//       const newMode = !prevMode;
//       localStorage.setItem('isDarkMode', JSON.stringify(newMode)); 
//       return newMode;
//     });
//   };

//   useEffect(() => {
//     document.body.classList.toggle('dark', isDarkMode);
//   }, [isDarkMode]);

//   return (
//     <ThemeContext.Provider value={{ isDarkMode, toggleTheme }}>
//       {children}
//     </ThemeContext.Provider>
//   );
// };

// export default ThemeProvider;
