import React, { useContext, useState } from "react";
import { ThemeContext } from "../theme/ThemeProvider";
import { useAuth } from "../hooks/AuthProvider";
import GoogleButton from "./GoogleButton";
import { loginApi, registerApi } from "../../utils/Api";

interface LoginProps {
  ModalOpen: boolean;
  closeModal: () => void;
}

const Login = ({ ModalOpen, closeModal }: LoginProps) => {
  const themeContext = useContext(ThemeContext);
  if (!themeContext) {
    throw new Error("ThemeContext must be used within a ThemeProvider");
  }

  const { login } = useAuth();
  const { isDarkMode } = themeContext;
  const [isLoginMode, setIsLoginMode] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [loading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const toggleMode = () => setIsLoginMode(!isLoginMode);

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await loginApi(email, password);
      if (!response) {
        console.log("please try again after some time");
      } else {
        if (response?.data?.token) {
          login(response.data.token);
        }
      }
      console.log(response.data.token);
    } catch (err) {
      // console.error("Error during login:", err);
      setError("Error during login");
    }
  };

  const handleRegister = async (e: React.FormEvent) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    if (!termsAccepted) {
      setError("You must accept the terms and conditions.");
      return;
    }

    const emailRegex = /^[a-zA-Z0-9._%+-]+@gmail\.com$/;
    if (!emailRegex.test(email)) {
      setError("Email must be a valid @gmail.com address.");
      return;
    }

    try {
      const response = await registerApi(name, email, mobile, confirmPassword);
      console.log("res", response);

      if (response) {
        login(response.token);
        setSuccessMessage("Registration successful!");
      } else {
        setError("Registration failed. Please try again.");
      }
    } catch (error) {
      // console.error("Error during registration:");
      setError("An error occurred during registration.");
    }
  };

  return (
    <div
      className={`${
        isDarkMode ? "bg-[#2e2e2e] text-[#ffff]" : "bg-[#ffff] text-black"
      }`}
    >
      {ModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 transition-opacity duration-75 ease-in-out ">
          <div className={`relative ${isDarkMode ? "bg-[#2e2e2e]" : "bg-white"} p-4 sm:p-6 lg:p-8 rounded-lg max-w-sm sm:max-w-md w-full shadow-lg transform transition-all duration-500 ease-in-out `} >
            <button
              onClick={closeModal}
              className={` absolute top-1 right-3 text-3xl ${isDarkMode ? " text-[#ffff]" : " text-black"}`}
            >
              &times;
            </button>
            <img
              className="h-16 w-20 mx-auto animate-fadeIn"
              src="images/BarelviLogo.png"
              alt="Logo"
            />
            <h1
              className={`mt-3 text-xl sm:text-2xl font-semibold capitalize text-center ${
                isDarkMode ? "text-[#ffff]" : "text-black"
              }`}
            >
              {isLoginMode ? "Sign In" : "Sign Up"}
            </h1>

            <form onSubmit={isLoginMode ? handleLogin : handleRegister}>
              {/* Show registration fields first when in registration mode */}
              {!isLoginMode && (
                <>
                  <div className="relative flex items-center mt-4">
                    <input
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      className="block w-full py-2 text-gray-700 bg-white border rounded-lg px-3 sm:px-6 dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 dark:focus:border-blue-300 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40"
                      placeholder="Full Name"
                      required
                    />
                  </div>

                  <div className="relative flex items-center mt-4">
                    <input
                      type="tel"
                      value={mobile}
                      onChange={(e) => setMobile(e.target.value)}
                      className="block w-full py-2 text-gray-700 bg-white border rounded-lg px-3 sm:px-6 dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 dark:focus:border-blue-300 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40"
                      placeholder="Mobile Number"
                      required
                    />
                  </div>

                  <div className="relative flex items-center mt-4">
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="block w-full py-2 text-gray-700 bg-white border rounded-lg px-3 sm:px-6 dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 dark:focus:border-blue-300 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40"
                      placeholder="Email address"
                      required
                    />
                  </div>

                  <div className="relative flex items-center mt-4">
                    <input
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className="block w-full px-3 sm:px-6 py-2 text-gray-700 bg-white border rounded-lg dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 dark:focus:border-blue-300 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40"
                      placeholder="Password"
                      required
                    />
                  </div>

                  <div className="relative flex items-center mt-4">
                    <input
                      type="password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      className="block w-full py-2 text-gray-700 bg-white border rounded-lg px-3 sm:px-6 dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 dark:focus:border-blue-300 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40"
                      placeholder="Confirm Password"
                      required
                    />
                  </div>

                  <div className="mt-4">
                    <label className="flex items-center">
                      <input
                        type="checkbox"
                        checked={termsAccepted}
                        onChange={() => setTermsAccepted(!termsAccepted)}
                        className="form-checkbox cursor-pointer"
                      />
                      <span className="ml-2 text-sm text-gray-600 dark:text-gray-300">
                        I accept the{" "}
                        <button className="text-blue-500 hover:underline">
                          terms and conditions
                        </button>
                      </span>
                    </label>
                  </div>
                </>
              )}

              {/* Common Fields for both Login and Registration */}
              {isLoginMode && (
                <>
                  <div className="relative flex items-center mt-4">
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="block w-full py-2 text-gray-700 bg-white border rounded-lg px-3 sm:px-6 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 dark:focus:border-blue-300 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40"
                      placeholder="Email address"
                      required
                    />
                  </div>

                  <div className="relative flex items-center mt-4">
                    <input
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className="block w-full px-3 sm:px-6 py-2 text-gray-700 bg-white border rounded-lg dark:bg-gray-900 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 dark:focus:border-blue-300 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40"
                      placeholder="Password"
                      required
                    />
                  </div>
                </>
              )}

              <div className="mt-6">
                <button
                  type="submit"
                  disabled={loading}
                  className="w-full px-6 py-2 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-500 rounded-lg hover:bg-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-50"
                >
                  {loading
                    ? isLoginMode
                      ? "Logging in..."
                      : "Registering..."
                    : isLoginMode
                    ? "Login"
                    : "Register"}
                </button>
              </div>
            </form>

            <div className="flex items-center justify-center mt-2">
              <hr className="w-1/4" />
              <span className="mx-2 text-gray-500">or</span>
              <hr className="w-1/4" />
            </div>

            <div>
              <GoogleButton onClose={closeModal}  />
            </div>

            <div className="mt-2 text-center">
              <button
                onClick={toggleMode}
                className="text-sm text-blue-500 hover:underline"
              >
                {isLoginMode
                  ? "Don't have an account? Sign Up"
                  : "Already have an account? Sign In"}
              </button>
            </div>

            {error && (
              <p className="mt-2 w-full  text-center text-red-500">{error}</p>
            )}
            {successMessage && (
              <p className="mt-2 text-center text-green-500">
                {successMessage}
              </p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;

