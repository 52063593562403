import { useContext } from "react";
import { ThemeContext } from "../theme/ThemeProvider";
import { NavLink } from "react-router-dom";

const data = [
  {
    title: "About",
    links: [
      { label: "About Us", link: "/about" },
      { label: "Terms and Conditions", link: "/termsandconditions" },
      { label: "Privacy and Policy", link: "/privacyandpolicy" },
      { label: "Refund Policy", link: "/refundpolicy" },
    ],
  },
  {
    title: "Services",
    links: [
      { label: "Coming Soon", link: "#" },
      { label: "Coming Soon", link: "#" },
      { label: "Coming Soon", link: "#" },
      { label: "Coming Soon", link: "#" },
    ],
  },
  {
    title: "Departments",
    links: [
      { label: "Coming Soon", link: "#" },
      { label: "Coming Soon", link: "#" },
      { label: "Coming Soon", link: "#" },
      { label: "Coming Soon", link: "#" },
    ],
  },
];

function Footer() {
  const themeContext = useContext(ThemeContext);
  if (!themeContext) {
    throw new Error("ThemeContext must be used within a ThemeProvider");
  }

  const { isDarkMode } = themeContext;

  const groups = data.map((group) => (
    <div key={group.title}>
      <h3
        className={`${isDarkMode ? " text-[#ffff]" : " text-[#000000]"
          } text-lg font-bold mb-3`}
      >
        {group.title}
      </h3>
      <ul>
        {group.links.map((link, index) => (
          <li key={index} className="mb-2">
            <NavLink
              to={link.link}
              className={`${isDarkMode ? "  text-[#B8B8B8]" : "bg-[#f8f9fa] text-[#b69B96]"
                } hover:text-blue-200 hover:underline`}
            >
              {link.label}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  ));

  const thisName = "Barelvi";

  return (
    <footer
      className={`${isDarkMode
        ? "bg-[#2e2e2e] lg:border-t lg:border-[#3b3b3b] md:border-t md:border-[#3b3b3b]"
        : "bg-[#f8f9fa] border-t border-[#E9ECEF] "
        } py-8`}
    >


      <div className="container mx-auto px-6 flex flex-col lg:flex-row lg:justify-between items-center">
        <div className="flex flex-col items-center lg:items-start  lg:mb-0 ">
          <img src="images/BarelviLogo.png" alt="Logo" className="w-16 h-16 mb-0" />
          <h2 className="text-2xl font-semibold bg-gradient-to-r from-red-500 to-yellow-400 bg-clip-text text-transparent">
            {thisName}
          </h2>
          <p className="text-sm text-[#6c8282]">
            A step towards digitalization for Islamic Learning
          </p>
        </div>
        <div className="hidden md:flex md:flex:row md:mt-4 lg:flex lg:flex-row justify-between w-full lg:w-1/2 ">
          {groups}
        </div>
      </div>

      <div
        className={`${isDarkMode ? "border-t border-[#424242]" : "border-t border-[#e9efec]"} py-4 mt-4 justify-between `}
      >
        <div className="container  mx-auto text-[#828282] text-sm px-6 text-center lg:flex lg:justify-between md:flex md:justify-between">
          <p>© 2024 {thisName}. All rights reserved.</p>
          <p>
            Developed By:{" "}
            <a
              href="http://www.11.Solutions"
              target="_blank"
              rel="noreferrer"
              className="text-blue-300 hover:underline"
            >
              Eleven Software Solutions Pvt. Ltd.
            </a>
          </p>
        </div>

      </div>


    </footer>
  );
}

export default Footer;