import React, { useContext } from "react";
import { ThemeContext } from "../theme/ThemeProvider";

const Home = () => {
  const themeContext = useContext(ThemeContext);
  if (!themeContext) {
    throw new Error("ThemeContext must be used within a ThemeProvider");
  }
  const { isDarkMode } = themeContext;
  return (
    <div
      className={`flex flex-col items-center justify-center md:h-screen ${
        isDarkMode
          ? "bg-globalBg border-b border-gray-700"
          : "bg-[#ffff] border-b border-gray-200"
      } `}
    >
      <div>
        <p className={`text-xl mb-4 ${ isDarkMode ? 'text-white' : 'text-black' }`}>Welcome to the Home Page</p>

        <div></div>
      </div>
    </div>
  );
};

export default Home;
