import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { FetchpageApi } from "../../../utils/Api";
import { getBooksData } from "../../../api/Books";
import { FaPlus } from "react-icons/fa";
import Select from "react-select";
import { fetchAuthorsApi } from "../../../api/Author";
import { fetchCategoriesApi } from "../../../api/Category";
import { usePageContext } from "./PageContext";

interface Page {
  id: string;
  bookId: string;
  bookName: string;
  content: string;
  pageNo: string;
  sequence: string;
}

const Pages = () => {
  const navigate = useNavigate();
  const [pages, setPages] = useState<Page[]>([]);
  const [filterModal, setfilterModal] = useState(false);
  const [books, setBooks] = useState<
    { id: string; name: string; authorId: string; categoryId: string }[]
  >([]);
  const [authors, setAuthors] = useState<{ id: string; name: string }[]>([]);
  const [categories, setCategories] = useState<{ id: string; name: string }[]>(
    []
  );
  const { filteredPages, setFilteredPages, selectedBookName, setSelectedBookName } = usePageContext();
  const { selectedAuthors, setSelectedAuthors } = usePageContext()
  const { selectedCategories, setSelectedCategories } = usePageContext()
  const [filteredBooks, setFilteredBooks] = useState<{ id: string; name: string }[]>([]);
  const { selectedBooks, setSelectedBooks } = usePageContext()


  const sortedBooks = filteredPages.sort((a, b) => {
    const pageNoA = parseInt(a.pageNo, 10);
    const pageNoB = parseInt(b.pageNo, 10);
    const sequenceA = parseInt(a.sequence, 10);
    const sequenceB = parseInt(b.sequence, 10);
    if (pageNoA === pageNoB) {
      return sequenceA - sequenceB;
    }
    return pageNoA - pageNoB;
  });

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        const [authorsData, categoriesData, booksData, pagesData] = await Promise.all([
          fetchAuthorsApi(),
          fetchCategoriesApi(),
          getBooksData(),
          FetchpageApi()
        ]);
        if (authorsData) {
          setAuthors(authorsData);
        } else {
          console.error("Failed to fetch authors");
        }
        if (categoriesData) {
          setCategories(categoriesData);
        } else {
          console.error("Failed to fetch categories");
        }
        setBooks(booksData);
        setPages(pagesData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchAllData();
  }, []);

  const handleAddButtonClick = () => {
    navigate("/admin/Pages/formpages");
  };

  const handleEditButtonClick = (id: string) => {
    navigate(`/admin/Pages/formpages`, { state: { id } });
  };

  const handledeleteclick = (id: string) => { };

  const [currentPage, setCurrentPage] = useState(1);
  const booksPerPage = 10;
  const totalPages = Math.ceil(sortedBooks.length / booksPerPage);

  const currentBooks = sortedBooks.slice(
    (currentPage - 1) * booksPerPage,
    currentPage * booksPerPage
  );

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };
  const handlePageClick = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const filterBooks = useCallback(() => {
    const filtered = books.filter((book) => {
      const isAuthorMatch =
        selectedAuthors.length === 0 ||
        selectedAuthors.includes(String(book.authorId));
      const isCategoryMatch =
        selectedCategories.length === 0 ||
        selectedCategories.includes(String(book.categoryId));
      return isAuthorMatch && isCategoryMatch;
    });
    setFilteredBooks(
      filtered.map((book) => ({ id: book.id, name: book.name }))
    );
  }, [books, selectedAuthors, selectedCategories]);

  useEffect(() => {
    filterBooks();
  }, [filterBooks]);


  const resetFields = () => {
    setSelectedAuthors([]);
    setSelectedCategories([]);
    setSelectedBooks("");
    setFilteredPages([])
  };

  const handleSaveClick = useCallback((bookId: string) => {
    const relatedPages = pages.filter((page) => page.bookId === bookId);
    setFilteredPages(relatedPages);
    setfilterModal(false);
  }, [pages, setFilteredPages]);

  useEffect(() => {
    selectedBookName.map((book) => handleSaveClick(book.id))
  }, [pages, selectedBookName, handleSaveClick]);

  const findNameWithId = useCallback(() => {
    books.forEach((book) => {
      if (book.id === selectedBooks) {
        const newBook = { id: book.id, name: book.name };
        setSelectedBookName([newBook]);
      }
    });
  }, [books, selectedBooks, setSelectedBookName]);

  useEffect(() => {
    findNameWithId();
  }, [findNameWithId]);


  return (
    <div className="w-full">
      <div className="p-4 w-full">
        <h2 className="text-3xl font-bold  text-blue-600 mt-2 ">Pages</h2>
        <form className="mx-auto mt-10 mr-16 ">

          <div className="relative w-full">
            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
              <svg
                className="w-4 h-4 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
            <div className="flex flex-row">
              <input
                type="search"
                id="default-search"
                className="inline-block min-w-full p-3 ps-10 outline-none text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Search Mockups, Logos..."
                required
              />
              <button
                type="submit"
                className="text-white absolute end-1.5 bottom-1.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Search
              </button>
              <button
                onClick={() => setfilterModal(true)}
                className="text-white ml-2 bottom-1.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                aria-label="Toggle Menu"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="icon icon-tabler icons-tabler-outline icon-tabler-filter"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M4 4h16v2.172a2 2 0 0 1 -.586 1.414l-4.414 4.414v7l-6 2v-8.5l-4.48 -4.928a2 2 0 0 1 -.52 -1.345v-2.227z" />
                </svg>
              </button>
            </div>
          </div>
        </form>
        <div className="flex flex-col mt-6 ">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden border border-gray-200 dark:border-gray-700 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                  <thead className="bg-gray-50 dark:bg-gray-800">
                    <tr>
                      <th
                        scope="col"
                        className="px-4 py-3.5 text-md font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                      >
                        Edit/Delete
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 px-4 text-md font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                      >
                        <div className="flex items-center gap-x-3">
                          <span>Name</span>
                        </div>
                      </th>

                      <th
                        scope="col"
                        className="px-12 py-3.5 text-md font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                      >
                        <button className="flex items-center gap-x-2">
                          <span>PageNumber</span>
                        </button>
                      </th>

                      <th
                        scope="col"
                        className="px-4 py-3.5 text-md font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                      >
                        <button className="flex items-center gap-x-2">
                          <span>Sequance</span>
                        </button>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
                    {currentBooks.length > 0
                      ? currentBooks.map((page) => (
                        <tr key={page.id}>
                          <td className="px-4 py-4 text-sm whitespace-nowrap">
                            <div className="flex items-center gap-x-6">
                              <button
                                onClick={() => handledeleteclick(page.id)}
                                className="text-gray-500 transition-colors duration-200 dark:hover:text-red-600 dark:text-gray-300 hover:text-red-500 focus:outline-none"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  className="w-5 h-5"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                  />
                                </svg>
                              </button>

                              <button
                                onClick={() => handleEditButtonClick(page.id)}
                                className="text-gray-500 transition-colors duration-200 dark:hover:text-yellow-500 dark:text-gray-300 hover:text-yellow-500 focus:outline-none"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  className="w-5 h-5"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                                  />
                                </svg>
                              </button>
                            </div>
                          </td>
                          <td className="px-4 py-4 text-sm font-medium text-gray-700 whitespace-nowrap">
                            <div className="inline-flex items-center gap-x-3">
                              <div className="flex items-center gap-x-2">
                                <div>
                                  <h2 className="  text-sm font-medium text-gray-800 dark:text-white ">
                                    {page.bookName}
                                  </h2>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="px-12 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">
                            {page.pageNo}
                          </td>
                          <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">
                            {page.sequence}
                          </td>
                        </tr>
                      ))
                      : null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-between mt-6">
          <button
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
            className="flex items-center px-5 py-2 text-sm text-gray-700 capitalize transition-colors duration-200 bg-white border rounded-md gap-x-2 hover:bg-gray-100 dark:bg-gray-900 dark:text-gray-200 dark:border-gray-700 dark:hover:bg-gray-800"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-5 h-5 rtl:-scale-x-100"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18"
              />
            </svg>

            <span>previous</span>
          </button>

          <div className="items-center hidden lg:flex gap-x-3">
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index + 1}
                onClick={() => handlePageClick(index + 1)}
                className={`px-2 py-1 text-sm ${currentPage === index + 1
                  ? "text-blue-500 bg-blue-100"
                  : "text-gray-500 hover:bg-gray-100"
                  } rounded-md`}
              >
                {index + 1}
              </button>
            ))}
          </div>
          <button
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
            className="flex items-center px-5 py-2 text-sm text-gray-700 capitalize transition-colors duration-200 bg-white border rounded-md gap-x-2 hover:bg-gray-100 dark:bg-gray-900 dark:text-gray-200 dark:border-gray-700 dark:hover:bg-gray-800"
          >
            <span>Next</span>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-5 h-5 rtl:-scale-x-100"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
              />
            </svg>
          </button>
        </div>

        <div className="flex gap-1">
          <button
            onClick={handleAddButtonClick}
            className="mt-4 flex items-center px-4 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-600 rounded-lg hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80"
          >
            <FaPlus className="mr-2 font-medium" />
            Pages
          </button>
        </div>

        <div className="inset-0 bg-gray-800 bg-opacity-50 z-50 transition-opacity duration-300">
          <div
            className={`fixed top-0 right-0 h-full w-[300px] md:w-[500px] bg-white p-4 rounded-md shadow-lg transform transition-transform duration-500 ease-in-out
          ${filterModal ? "translate-x-0" : "translate-x-full"}`}
          >
            <h1 className="text-2xl md:text-3xl font-bold text-blue-600 mb-4 md:mb-6">Filter</h1>
            <button
              onClick={() => setfilterModal(false)}
              className="absolute top-1 right-3 text-gray-400 hover:text-gray-600 text-2xl"
            >
              &times;
            </button>
            <div className="flex flex-col">
              <div className="mb-4 flex flex-col md:flex-row md:justify-between">
                <label className="block text-gray-700 mt-2 mr-0 md:mr-5">Authors</label>
                <div className="w-full mt-2 md:mt-0">
                  <Select
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    options={authors.map((author) => ({
                      label: author.name,
                      value: author.id,
                    }))}
                    value={authors
                      .filter((author) => selectedAuthors.includes(author.id))
                      .map((author) => ({
                        label: author.name,
                        value: author.id,
                      }))}
                    onChange={(selectedOptions: any) => {
                      setSelectedAuthors(
                        selectedOptions.map((option: any) => option.value)
                      );
                    }}
                  />
                </div>
              </div>
              <div className="mb-4 flex flex-col md:flex-row md:justify-between">
                <label className="block text-gray-700 mt-2 mr-0 md:mr-3">Category</label>
                <div className="w-full mt-2 md:mt-0">
                  <Select
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    options={categories.map((category) => ({
                      label: category.name,
                      value: category.id,
                    }))}
                    value={categories
                      .filter((category) =>
                        selectedCategories.includes(category.id)
                      )
                      .map((category) => ({
                        label: category.name,
                        value: category.id,
                      }))}
                    onChange={(selectedOptions: any) => {
                      setSelectedCategories(
                        selectedOptions.map((option: any) => option.value)
                      );
                    }}
                  />
                </div>
              </div>
              <div className="mb-4 flex flex-col md:flex-row md:justify-between">
                <label className="block text-gray-700 mt-2 mr-0 md:mr-10">Book</label>
                <div className="w-full mt-2 md:mt-0">
                  <Select
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    options={filteredBooks.map((book) => ({
                      label: book.name,
                      value: book.id,
                    }))}
                    value={
                      selectedBooks ? [{
                        label: filteredBooks.find((book) => book.id === selectedBooks)?.name || '',
                        value: selectedBooks,
                      }] : null
                    }
                    onChange={(selectedOption) => {
                      if (selectedOption) {
                        setSelectedBooks(selectedOption.value);
                      }
                    }}
                  />
                </div>
              </div>
              <div className="flex flex-row gap-3">
                {/* <button
                  // onClick={() => handleSaveClick(selectedBooks)}
                  className="mt-2 px-4 py-2 bg-blue-500 text-white rounded-md"
                >
                  Save
                </button> */}
                <button
                  onClick={resetFields}
                  className="mt-2 px-4 py-2 bg-blue-500 text-white rounded-md"
                >
                  Reset
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Pages;




