import React, { useState, useEffect, ChangeEvent } from 'react';
import { updateProfileApi, getProfileApi } from '../../utils/Api';
import Cookies from "universal-cookie";

interface UpdateUserDetailsProps {
  usermodalopen: boolean;
  closeusermodal: () => void;
}

const UpdateUserDetails: React.FC<UpdateUserDetailsProps> = ({ usermodalopen, closeusermodal }) => {
  const [name, setName] = useState<string>("");
  const [mobile, setMobile] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [user, setUser] = useState();

  const cookies = new Cookies();
  const token = cookies.get("authToken");

  const userToken = token?.data?.token || token;

  useEffect(() => {
    const fetchUserProfile = async () => {
      if (usermodalopen && userToken && !user) {
        try {
          const userProfile = await getProfileApi(userToken);
          console.log(userToken)
          setUser(userProfile);
          if (userProfile) {
            setName(userProfile.name);
            setMobile(userProfile.mobile);
            setAddress(userProfile.address);
          }
        } catch (error) {
          console.error("Error fetching user profile:", error);
        }
      }
    };

    fetchUserProfile();
  }, [usermodalopen, userToken, user]);



  const handleUpdate = async () => {
    try {
      const response = await updateProfileApi(userToken, name, mobile, address);
      if (response.status === 200) {
        alert('Updated Successfully');
        closeusermodal();
      } else {
        console.error("Update failed:", response.statusText);
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  }

  const handleMobileChange = (event: ChangeEvent<HTMLInputElement>) => {
    setMobile(event.target.value);
  }

  const handleAddressChange = (event: ChangeEvent<HTMLInputElement>) => {
    setAddress(event.target.value);
  }

  if (!usermodalopen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-75">
      <div className="bg-white rounded-lg p-6 w-full max-w-md relative">
        <button
          onClick={closeusermodal}
          className="absolute top-3 right-3 text-gray-400 hover:text-gray-600 text-2xl"
        >
          &times;
        </button>

        <h3 className="text-center text-xl font-bold mb-4">Update User Details</h3>

        <div className="mb-4">
          <label className="block text-sm font-medium mb-1">Name</label>
          <input
            type="text"
            placeholder="Name"
            value={name}
            onChange={handleNameChange}
            className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring focus:ring-indigo-500"
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium mb-1">Mobile</label>
          <input
            type="tel"
            placeholder="Mobile"
            value={mobile}
            onChange={handleMobileChange}
            className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring focus:ring-indigo-500"
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium mb-1">Address</label>
          <input
            type="text"
            placeholder="Address"
            value={address}
            onChange={handleAddressChange}
            className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring focus:ring-indigo-500"
          />
        </div>

        <div className="flex justify-end">
          <button
            onClick={handleUpdate}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-400"
          >
            Update Details
          </button>
        </div>
      </div>
    </div>
  );
};

export default UpdateUserDetails;
