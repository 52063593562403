import React, { useEffect, useState } from "react";
import "../App.css";
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  Navigate,
} from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import Header from "../Components/header/Header";
import Footer from "../Components/footer/Footer";
import Home from "../Components/home/Home";
import About from "../Components/about/About";
import Contact from "../Components/contacts/Contact";
import { AuthProvider, useAuth } from "../Components/hooks/AuthProvider";
import ThemeProvider from "../Components/theme/ThemeProvider";
import AdminSideBar from "../Components/admin/AdminSideBar";
import AdminDashBoard from "../../src/Components/admin/AdminSideBar";
import Languages from "../Components/admin/Languages";
import LanguageAddForm from "../Components/admin/LanguageAddForm";
import Pages from "../Components/admin/pages/Pages";
import Category from "../Components/admin/Category";
import CategoryForm from "../Components/admin/CategoryForm";
import AuthorForm from "../Components/admin/AuthorForm";
import TermsAndC from "../Components/termsAndConditions/TermsAndC";
import Privacy from "../Components/privacyAndPolicy/Privacy";
import Author from "../Components/admin/Author";
import Books from "../../src/Components/admin/Books";
import BooksForm from "../../src/Components/admin/BooksForm";
import FormPages from "../Components/admin/pages/FormPages";
import SectionPage from "../../src/Components/admin/sections/SectionPage";
import SectionPageForm from "../../src/Components/admin/sections/SectionPageForm";
import User from "../Components/admin/User";
import UserForm from "../Components/admin/UserForm";

const clientId = process.env.REACT_APP_GOOGLE_CLIENTID as string;

function AppRouter() {
  const location = useLocation();
  const { user } = useAuth();
  const [userType, setUserType] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const isAdminRoute = location.pathname.startsWith("/admin");

  useEffect(() => {
    if (user) {
      setUserType(user?.roleType);
    }
    setIsLoading(false);
  }, [user]);

  if (isLoading) {
    return (<div className="flex items-center justify-center">
      <div
        className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white"
        role="status">
        <span
          className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
        >Loading...</span>
      </div>
    </div>)
  }

  return (
    <AuthProvider>
      <ThemeProvider>
        {!isAdminRoute && <Header />}
        <Routes>
          <Route path="/home" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/termsandconditions" element={<TermsAndC />} />
          <Route path="/privacyandpolicy" element={<Privacy />} />

          {userType === 2 ? (
            // Admin can access these routes
            <Route path="/admin" element={<AdminSideBar />}>
              <Route path="dashboard" element={<AdminDashBoard />} />
              <Route path="languages" element={<Languages />} />
              <Route path="books" element={<Books />} />
              <Route path="books/booksform" element={<BooksForm />} />
              <Route path="languages/addform" element={<LanguageAddForm />} />
              <Route path="category" element={<Category />} />
              <Route path="category/categoryform" element={<CategoryForm />} />
              <Route path="author" element={<Author />} />
              <Route path="author/authorform" element={<AuthorForm />} />
              <Route path="pages" element={<Pages />} />
              <Route path="pages/formpages" element={<FormPages />} />
              <Route path="sections" element={<SectionPage />} />
              <Route path="sections/sectionsForm" element={<SectionPageForm />} />
              <Route path="user" element={<User />}></Route>
              <Route path="user/userform" element={<UserForm />}></Route>

            </Route>
          ) : (
            <Route path="*" element={<Navigate to="/home" />} />

          )}
        </Routes>
        {!isAdminRoute && <Footer />}
      </ThemeProvider>
    </AuthProvider>
  );
}
function Router() {
  return (
    <BrowserRouter>
      <GoogleOAuthProvider clientId={clientId}>
        <AuthProvider>
          <AppRouter />
        </AuthProvider>
      </GoogleOAuthProvider>
    </BrowserRouter>
  );
}

export default Router;
