import React, { useContext, useEffect, useMemo, useState } from "react";
import { ThemeContext } from "../theme/ThemeProvider";
import { CiLight, CiDark } from "react-icons/ci";
import Login from "../login/Login";
import { NavLink } from "react-router-dom";
import { IconUser } from "@tabler/icons-react";
import Cookies from "universal-cookie";
import { useAuth } from "../hooks/AuthProvider";
import UpdateUserDetails from "../useraccount/UpdateUserDetails";

function Header() {
  const { user } = useAuth();
  const [userType, setUserType] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [showUserCard, setShowUserCard] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [usermodalopen, setusermodalopen] = useState(false);
  const [, setIsLoading] = useState(true);

  const themeContext = useContext(ThemeContext);
  const { logout } = useAuth();

  const cookies = useMemo(() => new Cookies(), []);
  useEffect(() => {
    if (user) {
      setUserType(user?.roleType);
    }
    setIsLoading(false);
  }, [user]);

  if (!themeContext) {
    throw new Error("ThemeContext must be used within a ThemeProvider");
  }
  const { isDarkMode, toggleTheme } = themeContext;

  useEffect(() => {
    const authToken = cookies.get("authToken");
    setIsLoggedIn(!!authToken);
  }, [cookies]);

  return (
    <nav
      className={`relative ${isDarkMode
          ? "bg-globalBg border-b border-gray-700"
          : "bg-[#ffff] border-b border-gray-200"
        }`}
    >
      <div
        className={` pl-4 pr-4 mx-auto ${isDarkMode ? "bg-globalBg " : "bg-[#ffff]"
          }`}
      >
        <div className="flex items-center justify-between">
          <div>
            <NavLink to="/home" className="flex items-center justify-between gap-4">
              <img
                className="w-auto h-14 sm:h-10 md:h-14 lg:h-14"
                src="images/BarelviLogo.png"
                alt="Logo"
              />
              <span className="text-2xl font-bold bg-gradient-to-r from-red-500 to-yellow-400 bg-clip-text text-transparent ">
                Barelvi
              </span>
            </NavLink>
          </div>

          <div className="md:hidden lg:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              type="button"
              className={`${isDarkMode ? " text-white" : "text-black"}`}
              aria-label="toggle menu"
            >
              {!isOpen ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4 8h16M4 16h16"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              )}
            </button>
          </div>

          {/* Menu Links */}
          <div
            className={` ${isOpen ? "translate-x-0" : "translate-x-full md:translate-x-0"
              }  
              transform transition-transform duration-300 ease-in-out w-full md:w-auto mt-20 md:mt-0 fixed md:relative top-0 right-0  md:top-auto md:bg-transparent md:h-auto z-50 md:z-auto  ${isOpen
                ? isDarkMode
                  ? "bg-[#2e2e2e] text-[#ffff]"
                  : "bg-[#ffff] text-black"
                : ""
              }`}
          >
            <div className="flex flex-col md:flex-row md:items-center md:space-x-4 p-4 md:p-0">
              <NavLink
                to="/home"
                className={({ isActive }) =>
                  `px-3 py-2 rounded-md ${isActive
                    ? "bg-blue-500 text-white"
                    : isDarkMode
                      ? "text-white hover:bg-gray-600 "
                      : "text-black hover:bg-gray-200"
                  }`
                }
              >
                Home
              </NavLink>
              <NavLink
                to="/about"
                className={({ isActive }) =>
                  `px-3 py-2 rounded-md ${isActive
                    ? "bg-blue-500 text-white"
                    : isDarkMode
                      ? "text-white hover:bg-gray-600 "
                      : "text-black hover:bg-gray-200"
                  }`
                }
              >
                About Us
              </NavLink>
              <NavLink
                to="/contact"
                className={({ isActive }) =>
                  `px-3 py-2 rounded-md ${isActive
                    ? "bg-blue-500 text-white"
                    : isDarkMode
                      ? "text-white hover:bg-gray-600 "
                      : "text-black hover:bg-gray-200"
                  }`
                }
              >
                Contact Us
              </NavLink>

              {/* User Authentication Options */}
              {isLoggedIn ? (
                <div className="relative">
                  <IconUser
                    className="w-10 h-7 cursor-pointer text-blue-400 rounded-md hidden md:block"
                    onMouseEnter={() => setShowUserCard(true)}
                  />
                  {showUserCard && (
                    <div
                      className={`absolute top-10 cursor-pointer right-0 p-2 w-52 shadow-lg rounded-lg border border-gray-200 ${isDarkMode
                          ? "bg-[#2e2e2e] border-b border-gray-700"
                          : "bg-[#ffff] border-b border-gray-200"
                        } `}
                      onMouseEnter={() => setShowUserCard(true)}
                      onMouseLeave={() => setShowUserCard(false)}
                    >
                      {/* Conditionally render Admin NavLink if userType is 2 */}
                      {userType === 2 && (
                        <NavLink
                          to="/admin"
                          className={`block px-4 py-2 rounded-md hover:bg-blue-400 transition-colors duration-200 ${isDarkMode
                              ? "text-white hover:bg-gray-600"
                              : "text-black hover:bg-gray-200"
                            }`}
                        >
                          Admin
                        </NavLink>
                      )}
                      <p
                        onClick={() => setusermodalopen(true)}
                        className={`block px-4 py-2 rounded-md hover:bg-blue-400 transition-colors duration-200 ${isDarkMode
                            ? "text-white hover:bg-gray-600"
                            : "text-black hover:bg-gray-200"
                          }`}
                      >
                        Update Details
                      </p>
                      <p
                        onClick={logout}
                        className={`block px-4 py-2 rounded-md hover:bg-blue-400 transition-colors duration-200 ${isDarkMode
                            ? "text-white hover:bg-gray-600"
                            : "text-black hover:bg-gray-200"
                          }`}
                      >
                        Logout
                      </p>
                    </div>
                  )}
                </div>
              ) : (
                <button
                  onClick={() => setIsLoginModalOpen(true)}
                  className={`px-3 py-2 mr-auto rounded-md ${isDarkMode
                      ? "text-white hover:bg-gray-600"
                      : "text-black hover:bg-gray-200"
                    }`}
                >
                  Login
                </button>
              )}

              <button
                onClick={toggleTheme}
                className={` px-3 py-1 mr-auto ml-3  lg:mt-auto lg:px-3 lg:py-1 border-2 rounded-md transition-transform transform hover:scale-95 duration-300 ${isDarkMode
                    ? "text-white border-white hover:border-blue-600"
                    : "text-black border-black hover:border-blue-600"
                  } `}
              >
                {isDarkMode ? (
                  <CiDark className="w-5 h-4 sm:w-6 sm:h-6" />
                ) : (
                  <CiLight className="w-5 h-4 sm:w-6 sm:h-6" />
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      <Login
        ModalOpen={isLoginModalOpen}
        closeModal={() => setIsLoginModalOpen(false)}
      />
      <UpdateUserDetails
        usermodalopen={usermodalopen}
        closeusermodal={() => setusermodalopen(false)}
      />
    </nav>
  );
}

export default Header;
