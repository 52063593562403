import React, { useState } from "react";
import { useContext } from "react";
import { ThemeContext } from "../theme/ThemeProvider";
import { Link, Outlet } from "react-router-dom";
import { RxHamburgerMenu } from "react-icons/rx";
import { ImCross } from "react-icons/im";
import { useAuth } from "../hooks/AuthProvider";

function AdminSideBar() {
  const { logout } = useAuth();
  const [HamBurger, setHamBurger] = useState(false);
  const themeContext = useContext(ThemeContext);
  if (!themeContext) {
    throw new Error("ThemeContext must be used within a ThemeProvider");
  }

  const HandleClick = () => {
    console.log("clickedd");
    setHamBurger(!HamBurger);
  };

  return (
    <div className="flex h-full">
      <aside
        className={`absolute ${
          HamBurger ? "left-0" : "-left-[60vh]"
        } sm:left-0 sm:relative z-20 sm:flex flex-col w-55 md:w-64 h-screen overflow-y-auto px- py-2  bg-white border-r rtl:border-r-0 rtl:border-l dark:bg-gray-900 dark:border-gray-700`}
      >
        <div className="pb-2 mx-auto">
          <Link
            to="/home"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent:"center",
            }}
          >
            <img
              className="w-auto h-8  sm:h-10 md:h-12 lg:h-14"
              src="/images/BarelviLogo.png"
              alt="Logo"
            />

            <span className="text-2xl font-semibold bg-gradient-to-r from-red-500 to-yellow-400 bg-clip-text text-transparent">
              Barelvi
            </span>
          </Link>
        </div>

        <div className=" my-4">
          <nav className="mx-3 space-y-6 ">
            <div className="space-y-3 ">
              <label className="px-3 text-xs text-gray-500 uppercase dark:text-gray-400">
                <span>Analytics</span>
              </label>
              <Link
                to="/admin/dashboard"
                className="flex items-center px-3 py-2 text-gray-600 transition-colors duration-300 transform rounded-lg dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-gray-200 hover:text-gray-700"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="w-5 h-5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M3.75 3v11.25A2.25 2.25 0 006 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0118 16.5h-2.25m-7.5 0h7.5m-7.5 0l-1 3m8.5-3l1 3m0 0l.5 1.5m-.5-1.5h-9.5m0 0l-.5 1.5m.75-9l3-3 2.148 2.148A12.061 12.061 0 0116.5 7.605"
                  />
                </svg>

                <span className={"mx-2  md:flex text-sm font-medium"}>
                  <span>Dashboard</span>
                </span>
              </Link>

              <Link
                to="#"
                className="flex items-center px-3 py-2 text-gray-600 transition-colors duration-300 transform rounded-lg dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-gray-200 hover:text-gray-700"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="w-5 h-5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M3.75 3v11.25A2.25 2.25 0 006 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0118 16.5h-2.25m-7.5 0h7.5m-7.5 0l-1 3m8.5-3l1 3m0 0l.5 1.5m-.5-1.5h-9.5m0 0l-.5 1.5M9 11.25v1.5M12 9v3.75m3-6v6"
                  />
                </svg>

                <div className="mx-2  md:flex text-sm font-medium">
                  <span>Performance</span>
                </div>
              </Link>
            </div>

            <div className="space-y-3 ">
              <label className="px-3 text-xs text-gray-500 uppercase dark:text-gray-400">
                <span>Library</span>
              </label>

              <Link
                to="/admin/books"
                className="flex items-center px-3 py-2 text-gray-600 transition-colors duration-300 transform rounded-lg dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-gray-200 hover:text-gray-700"
              >
                <svg
                  className="h-5 w-5 "
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" />{" "}
                  <path d="M3 19a9 9 0 0 1 9 0a9 9 0 0 1 9 0" />{" "}
                  <path d="M3 6a9 9 0 0 1 9 0a9 9 0 0 1 9 0" />{" "}
                  <line x1="3" y1="6" x2="3" y2="19" />{" "}
                  <line x1="12" y1="6" x2="12" y2="19" />{" "}
                  <line x1="21" y1="6" x2="21" y2="19" />
                </svg>

                <span className="mx-2 md:flex text-sm font-medium">
                  <span>Books</span>
                </span>
              </Link>

              <Link
                to="/admin/pages"
                className="flex items-center px-3 py-2 text-gray-600 transition-colors duration-300 transform rounded-lg dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-gray-200 hover:text-gray-700"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="w-5 h-5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                  />
                </svg>

                <span className="mx-2 md:flex text-sm font-medium">
                  <span>Pages</span>
                </span>
              </Link>

              <Link
                to="sections"
                className="flex items-center px-3 py-2 text-gray-600 transition-colors duration-300 transform rounded-lg dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-gray-200 hover:text-gray-700"
              >
                <svg
                  className="h-5 w-5 "
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" />{" "}
                  <line x1="9" y1="12" x2="15" y2="12" />{" "}
                  <line x1="12" y1="9" x2="12" y2="15" />{" "}
                  <path d="M4 6v-1a1 1 0 0 1 1 -1h1m5 0h2m5 0h1a1 1 0 0 1 1 1v1m0 5v2m0 5v1a1 1 0 0 1 -1 1h-1m-5 0h-2m-5 0h-1a1 1 0 0 1 -1 -1v-1m0 -5v-2m0 -5" />
                </svg>

                <span className="mx-2 md:flex text-sm font-medium">
                  <span>Sections</span>
                </span>
              </Link>

              <Link
                to="/admin/author"
                className="flex items-center px-3 py-2 text-gray-600 transition-colors duration-300 transform rounded-lg dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-gray-200 hover:text-gray-700"
              >
                <svg
                  className="h-5 w-5 "
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" />{" "}
                  <circle cx="8.5" cy="7" r="4" />{" "}
                  <polyline points="17 11 19 13 23 9" />
                </svg>

                <span className="mx-2 md:flex text-sm font-medium">
                  <span>Authors</span>
                </span>
              </Link>

              <Link
                to="/admin/category"
                className="flex items-center px-3 py-2 text-gray-600 transition-colors duration-300 transform rounded-lg dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-gray-200 hover:text-gray-700"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="w-5 h-5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z"
                  />
                </svg>

                <span className="mx-2 md:flex text-sm font-medium">
                  <span>Categories</span>
                </span>
              </Link>

              <Link
                to="/admin/languages"
                className="flex items-center px-3 py-2 text-gray-600 transition-colors duration-300 transform rounded-lg dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-gray-200 hover:text-gray-700"
              >
                <svg
                  className="h-5 w-5 "
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" />{" "}
                  <path d="M5 7h7m-2 -2v2a5 8 0 0 1 -5 8m1 -4a7 4 0 0 0 6.7 4" />{" "}
                  <path d="M11 19l4 -9l4 9m-.9 -2h-6.2" />
                </svg>

                <span className="mx-2 md:flex text-sm font-medium">
                  <span>Languages</span>
                </span>
              </Link>
            </div>

            <div className="space-y-3 ">
              <label className="px-3 text-xs text-gray-500 uppercase dark:text-gray-400">
                <span>Customization</span>
              </label>

              <Link
                to="/admin/user"
                className="flex items-center px-3 py-2 text-gray-600 transition-colors duration-300 transform rounded-lg dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-gray-200 hover:text-gray-700"
              >
                <svg
                  className="h-5 w-5 "
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" />{" "}
                  <circle cx="9" cy="7" r="4" />{" "}
                  <path d="M23 21v-2a4 4 0 0 0-3-3.87" />{" "}
                  <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                </svg>

                <span className="mx-2 md:flex text-sm font-medium">
                  <span>Users</span>
                </span>
              </Link>

              <Link
                to="#"
                className="flex items-center px-3 py-2 text-gray-600 transition-colors duration-300 transform rounded-lg dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-gray-200 hover:text-gray-700"
              >
                <svg
                  className="h-5 w-5 "
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" />
                  <line x1="9" y1="14" x2="15" y2="8" />
                  <circle cx="9.5" cy="8.5" r=".5" fill="currentColor" />
                  <circle cx="14.5" cy="13.5" r=".5" fill="currentColor" />
                  <path d="M5 21v-16a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v16l-3 -2l-2 2l-2 -2l-2 2l-2 -2l-3 2" />
                </svg>

                <span className="mx-2 md:flex text-sm font-medium">
                  <span>Taxonomies</span>
                </span>
              </Link>
            </div>
          </nav>

          <div className="pt-4 mt-4 border-t border-gray-300 ">
            <Link
              to="/home"
              className="flex items-center px-0 py-2 ml-4 transition-colors duration-300 transform rounded-lg dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-gray-200 hover:text-gray-700"
            >
              <svg
                className="h-5 w-5 "
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
                <polyline points="9 22 9 12 15 12 15 22" />
              </svg>
              <span className="mx-2 md:flex text-sm font-medium text-gray-600">
                <span>Back To Home</span>
              </span>
            </Link>

            <Link
              to="#"
              className="flex items-center px-0 py-2 ml-4 transition-colors duration-300 transform rounded-lg dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-gray-200 hover:text-gray-700"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-5 h-5  text-gray-600 "
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-7.5A2.25 2.25 0 003.75 5.25v13.5A2.25 2.25 0 006 21h7.5a2.25 2.25 0 002.25-2.25V15"
                />
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M9 12h12m0 0l-3-3m3 3l-3 3"
                />
              </svg>
              <span className="mx-2 md:flex text-sm font-medium text-gray-600 ">
                <span onClick={logout} >Logout</span>
              </span>
            </Link>
          </div>
        </div>
      </aside>
      <div className=" w-full">
        <div className=" flex justify-end">
          <button className="m-2 sm:hidden" onClick={HandleClick}>
            {HamBurger ? <ImCross /> : <RxHamburgerMenu />}
          </button>
        </div>
        <Outlet />
      </div>
    </div>
  );
}

export default AdminSideBar;
