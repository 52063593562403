import React from "react";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { addUserApi, updateUserApi, fetchUserByIdApi } from "../../api/User";

const UserForm = () => {
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [address, setAddress] = useState("");
  const [roleType, setRoleType] = useState("");

  const [, setUser] = useState<any>(null);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const validateForm = () => {
    if (!userName || !email || !roleType) {
      alert(
        "Please fill in all required fields: User Name, Email, and Role Type."
      );
      return false;
    }
    return true;
  };

  const location = useLocation();
  const navigate = useNavigate();

  const { id } = location.state || {};

  useEffect(() => {
    if (id) {
      const fetchUserData = async () => {
        try {
          const fetchedUser = await fetchUserByIdApi(id);
          if (fetchedUser) {
            setIsEditMode(true);
            setUser(fetchedUser);
            setUserName(fetchedUser.name);
            setEmail(fetchedUser.email);
            setMobile(fetchedUser.mobile);
            setAddress(fetchedUser.address);
            setRoleType(fetchedUser.roleType);
          }
        } catch (error) {
          console.error("Unable to fetch user data", error);
        }
      };
      fetchUserData();
    }
  }, [id]);

  const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (!validateForm()) return;

    try {
      if (isEditMode) {
        await updateUserApi(id, userName, mobile, address, roleType);
        alert("User updated successfully");
      } else {
        await addUserApi(userName, email, mobile, address, roleType);
        alert("User added successfully");
      }
      navigate("/admin/user");
    } catch (error) {
      console.error("Error adding/updating User:", error);
      alert("Failed to add/update User");
    }
  };

  const handleCancelClick = () => {
    setUserName("");
    setEmail("");
    setMobile("");
    setAddress("");
    setRoleType("");
    navigate("/admin/user");
  };
  return (
    <div className="max-w-4xl p-6 mx-auto dark:bg-gray-800">
      <div>
        <form>
          <h1 className="text-3xl font-bold text-center text-blue-600 capitalize dark:text-white mb-4">
            {isEditMode ? "Edit User" : "Add User"}
          </h1>

          <div className="mb-6 w-full">
            <label className="text-gray-900 dark:text-gray-200 font-semibold">
              User Name <span className="text-red-500">*</span>
            </label>
            <input
              required
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              type="text"
              className="w-full px-4 py-2 mt-2  text-gray-800  bg-white border border-gray-400 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-800 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
              placeholder="Enter User Name"
            />
          </div>
          <div className="mb-6 w-full">
            <label className="text-gray-900 dark:text-gray-200 font-semibold">
              Email <span className="text-red-500">*</span>
            </label>
            <input
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              className="w-full px-4 py-2 mt-2  text-gray-800  bg-white border border-gray-400 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-800 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
              placeholder="Enter Email"
              disabled={isEditMode}
            />
          </div>
          <div className="mb-6 w-full">
            <label className="text-gray-900 dark:text-gray-200 font-semibold">
              Moblie Number
            </label>
            <input
              required
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
              type="number"
              className="w-full px-4 py-2 mt-2  text-gray-800  bg-white border border-gray-400 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-800 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
              placeholder="Enter Mobile Number"
            />
          </div>
          <div className="mb-6 w-full">
            <label className="text-gray-900 dark:text-gray-200 font-semibold">
              Address
            </label>
            <input
              required
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              type="text"
              className="w-full px-4 py-2 mt-2  text-gray-800  bg-white border border-gray-400 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-800 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
              placeholder="Address"
            />
          </div>

          <div className="mb-6 w-full">
            <label className="text-gray-900 dark:text-gray-200 font-semibold">
              Role Type <span className="text-red-500">*</span>
            </label>
            <select
              required
              value={roleType}
              onChange={(e) => setRoleType(e.target.value)}
              className="w-full px-4 py-2 mt-2  text-gray-800  bg-white border border-gray-400 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-800 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
            >
              <option value="">Select a Role Type</option>
              <option value="1">Guest</option>
              <option value="2">Admin</option>
            </select>
          </div>

          <div className="flex space-x-4 mt-6 justify-end">
            <button
              type="submit"
              onClick={handleSubmit}
              className="px-8 py-2.5 leading-5 text-white transition-colors duration-300 transform bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus:bg-gray-600"
            >
              {isEditMode ? "Edit" : "Add"}
            </button>
            <button
              type="button"
              onClick={handleCancelClick}
              className="px-8 py-2.5 leading-5 text-white transition-colors duration-300 transform bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus:bg-gray-600"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UserForm;
