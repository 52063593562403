import React, { useContext } from "react";
import { ThemeContext } from "../theme/ThemeProvider";

function About() {
  const themeContext = useContext(ThemeContext);
  if (!themeContext) {
    throw new Error("ThemeContext must be used within a ThemeProvider");
  }

  const { isDarkMode } = themeContext;

  return (
    <div
      className={`${
        isDarkMode ? "bg-globalBg text-white" : "bg-[#FFFF]"
      } text-justify flex flex-col justify-center items-center py-8`}  
    >
      <div className="max-w-[800px] text-center px-4">
        <h2 className="font-greycliff-cf text-blue-500 mt-0 text-3xl md:text-4xl lg:text-2xl font-bold">
          About Us
        </h2>
        <div className="text-gray-500 mt-2 space-y-4 text-justify">
          <p className="text-sm sm:text-base md:text-lg lg:text-base">
            Welcome to TajushShariah Foundation, a digital sanctuary dedicated
            to fostering spiritual growth based on the teachings of Imam Ahmed
            Raza.
          </p>
          <p className="text-sm sm:text-base md:text-lg lg:text-base">
            We are a community of believers, thinkers, and doers, united by our
            shared Maslak-e-Ala Hazrat faith and commitment to the betterment of
            humanity. We are based out of Hyderabad, Telangana, India.
          </p>
          <p className="text-sm sm:text-base md:text-lg lg:text-base">
            Our mission is to leverage the power of digital platforms for Ulemas
            and public learning to make religious education accessible to all,
            regardless of geographical boundaries or socio-economic conditions.
            Our platform offers a comprehensive suite of digital resources for
            Ulemas and awaam, all designed to deepen your understanding of our
            faith and its teachings.
          </p>
          <p className="text-sm sm:text-base md:text-lg lg:text-base">
            We believe that knowledge is the cornerstone of faith, and through
            our digital learning initiatives, we aim to empower our community
            members to explore, understand, and apply religious teachings in
            their daily lives. But our work doesn’t stop at digital learning. We
            are equally committed to social upliftment. We believe that faith
            calls us not only to personal growth but also to active
            participation in the betterment of our society. Through various
            outreach programs and initiatives, we strive to address social
            issues, promote equality, and help those in need. Join us on this
            journey of faith, learning, and service. Together, we can build a
            more compassionate, understanding, and equitable world.
          </p>
        </div>
      </div>
    </div>
  );
}

export default About;

