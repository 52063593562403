import React, { useEffect, useState } from "react";
import {
  MdAdd,
  MdEdit,
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp,
} from "react-icons/md";
import { getBooksData } from "../../../api/Books";
import {
  createSectionApi,
  getsectionsData,
  updateSectionApi,
} from "../../../api/Section";
import { getPagesByBookIdApi } from "../../../api/Pages";

interface Section {
  id: string;
  name: string;
  parentId: string | null;
  pageId?: string;
  bookId: string;
  subsections?: Section[];
}

type Book = {
  id: string;
  name: string;
};

const SectionPage: React.FC = () => {
  // Track selected book ID
  const [expandedSections, setExpandedSections] = useState<{
    [key: string]: boolean;
  }>({});
  const [activeSectionId, setActiveSectionId] = useState<string | null>(null);
  const [editingSectionId, setEditingSectionId] = useState<string>("");
  const [inputValues, setInputValues] = useState<{
    name: string;
    pageId: string;
  }>({ name: "", pageId: "" });
  const [selectedBook, setSelectedBook] = useState<string>("");
  const [addingNewSection, setAddingNewSection] = useState(false); 
  const [booksData, setBooksData] = useState<Book[]>([]); 
  const [sectionsData, setSectionsData] = useState<Section[]>([]); 
  const [filteredSections, setFilteredSections] = useState<Section[]>([]); 
  const [pages, setPages] = useState([]); 

  const handleBookChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    const bookId = e.target.value; // Get the bookId from the event
  
    setSelectedBook(bookId);
    setExpandedSections({});
    setActiveSectionId(null);
    setAddingNewSection(false);
  
    if (bookId) {
      try {
        // Fetch the sections for the selected book
        const sections = await getsectionsData(); // You might have to pass bookId in your API call
        setSectionsData(sections); // Update state with fetched sections
        
        // Immediately filter sections based on the selected book
        const filtered = sections.filter((section:any) => section.bookId === bookId);
        setFilteredSections(filtered); // Set the filtered sections
        
      } catch (error) {
        console.error("Error fetching sections:", error);
      }
  
      try {
        // Fetch pages for the selected book (optional)
        const pages = await getPagesByBookIdApi(bookId);
        setPages(pages);
        console.log("Pages fetched successfully:", pages);
      } catch (error) {
        console.error("Unable to fetch Pages", error);
      }
    } else {
      // Reset UI if no book is selected
      setFilteredSections([]);
      setPages([]);
    }
  };
  

  const toggleExpand = (sectionId: string) => {
    setExpandedSections((prev) => ({
      ...prev,
      [sectionId]: !prev[sectionId],
    }));
  };

  const handleEditSection = async (
    id: string,
    bookId: string,
    name: string,
    parentId: string | null,
    pageId: string
  ) => {
    try {
      // Call the API to update the section
      await updateSectionApi(id, bookId, name, parentId, pageId);
      alert("Section updated successfully");
  
      // Update sectionsData state with the modified section
      setSectionsData((prevSections) => {
        // Update the specific section
        const updatedSections = prevSections.map((section) =>
          section.id === id
            ? { ...section, name, parentId, pageId, bookId }
            : section
        );
  
        // Re-filter the sections for the selected book using the updated sections data
        const filtered = updatedSections.filter(
          (section) => section.bookId === selectedBook
        );
  
        // Update filteredSections with the updated data
        setFilteredSections(filtered);
  
        return updatedSections; // Return the updated sections data
      });
  
      // Reset the form and UI state after the update
      setActiveSectionId(null);
      setInputValues({ name: "", pageId: "" });
    } catch (error) {
      console.error("Error in handleEditSection:", error);
    }
  };
  

  const handleAddSections = async (
    name: string,
    parentId: string | null,
    pageId: string
  ) => {
    if (!selectedBook) {
      console.error("No book selected");
      return;
    }

    const newSection = {
      id: Date.now().toString(),
      name,
      parentId,
      pageId,
      bookId: selectedBook,
    };

    try {
      await createSectionApi(selectedBook, name, parentId, pageId);
      alert("sections added succesfully");
      setSectionsData((prevSections) => [...prevSections, newSection]);
      const filtered = [...sectionsData, newSection].filter(
        (section) => section.bookId === selectedBook
      );
      setFilteredSections(filtered);
    } catch (error) {
      console.error("Error adding section:", error);
    }
  };

  const handleBooks = async () => {
    try {
      const result = await getBooksData();
      setBooksData(result);
      console.log(result);
    } catch (error) {
      console.error("Error fetching books:", error);
    }
  };

  useEffect(() => {
    handleBooks();
  }, []);

  const renderSubSections = (parentSectionId: string, sections: Section[]) => {
    const childSections = sections.filter(
      (section) => section.parentId === parentSectionId
    );

    if (childSections.length === 0) return null;
    console.log("rendering sub sections :", childSections);
    return (
      <div className="pl-5 ">
        {childSections.map((section) => (
          <div
            key={section.id}
            className=" border-gray-200 py-2 hover:bg-gray-200 transition duration-300"
          >
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                {sections.some((s) => s.parentId === section.id) && (
                  <button
                    onClick={() => toggleExpand(section.id)}
                    className="mr-2"
                  >
                    {expandedSections[section.id] ? (
                      <MdOutlineKeyboardArrowUp
                        size={20}
                        className="text-black"
                      />
                    ) : (
                      <MdOutlineKeyboardArrowDown
                        size={20}
                        className="text-black"
                      />
                    )}
                  </button>
                )}
                <span className="text-gray-700 text-lg font-medium">
                  {section.name}
                </span>
              </div>

              <div className="flex items-center space-x-2">
                <button
                  onClick={() => setActiveSectionId(section.id)}
                  className="text-blue-500 hover:text-blue-700 transition duration-200"
                >
                  <MdAdd size={20} />
                </button>
                <button
                  onClick={() => {
                    setEditingSectionId(section.id);
                    setInputValues({
                      name: section.name,
                      pageId: section.pageId || "",
                    });
                  }}
                  className="text-yellow-500 hover:text-yellow-700 transition duration-200"
                >
                  <MdEdit size={20} />
                </button>
              </div>
            </div>
            {activeSectionId === section.id && (
              <div className="ml-6 mt-2">
                <input
                  type="text"
                  placeholder="Section Name"
                  onChange={(e) =>
                    setInputValues((prev) => ({
                      ...prev,
                      name: e.target.value,
                    }))
                  }
                  className="border border-gray-300 px-2 py-1 rounded focus:border-green-500 focus:ring-green-500"
                />
                {pages.length > 0 ? (
                  <select
                    onChange={(e) =>
                      setInputValues((prev) => ({
                        ...prev,
                        pageId: e.target.value,
                      }))
                    }
                    className="border border-gray-300 px-2 py-1 rounded sm:ml-2 focus:border-green-500 focus:ring-green-500"
                  >
                    <option value="">Select Page No</option>
                    {pages.map((option: any, index: number) => (
                      <option key={index} value={option.id}>
                        {option.pageNo}
                      </option>
                    ))}
                  </select>
                ) : (
                  <p>No pages found</p>
                )}
                <div className="mt-3">
                  <button
                    onClick={() =>
                      handleAddSections(
                        inputValues.name,
                        section.id,
                        inputValues.pageId
                      )
                    }
                    className="ml-2 px-3 py-1 bg-green-500 hover:bg-green-600 text-white rounded transition duration-200"
                  >
                    Add
                  </button>
                  <button
                    onClick={() => setActiveSectionId(null)}
                    className="ml-2 px-3 py-1 bg-gray-500 hover:bg-green-600 text-white rounded transition duration-200"
                  >
                    cancel
                  </button>
                </div>
              </div>
            )}

            {/* Recursively render child sections if expanded */}
            {expandedSections[section.id] &&
              renderSubSections(section.id, sections)}

            {editingSectionId === section.id && (
              <div className="ml-6 mt-2">
                <input
                  type="text"
                  value={inputValues.name}
                  onChange={(e) =>
                    setInputValues((prev) => ({
                      ...prev,
                      name: e.target.value,
                    }))
                  }
                  className="border border-gray-300 px-2 py-1 rounded"
                />
                <select
                  value={inputValues.pageId}
                  onChange={(e) =>
                    setInputValues((prev) => ({
                      ...prev,
                      pageId: e.target.value,
                    }))
                  }
                  className="border border-gray-300 px-2 py-1 rounded sm:ml-2 focus:border-green-500 focus:ring-green-500"
                >
                  <option value="">Select Page No</option>
                  {pages.map((option: any, index: number) => (
                    <option key={index} value={option.id}>
                      {option.pageNo}
                    </option>
                  ))}
                </select>

                {/* Save Changes Button */}
                <button
                  onClick={() =>
                  {

                    handleEditSection(
                      section.id, // Current section ID
                      selectedBook, // Book ID from your state
                      inputValues.name, // Updated name
                      section.parentId || null, // Set parentId to null if it's a root section
                      inputValues.pageId // Updated page ID
                    )
                    setEditingSectionId("");
                    setInputValues({ name: "", pageId: "" });
                  }
                  }
                  className="ml-2 px-3 py-1 bg-blue-500 text-white rounded"
                >
                  Save
                </button>
                <button
                  onClick={() => setEditingSectionId("")}
                  className="ml-2 px-3 py-1 bg-gray-500 hover:bg-green-600 text-white rounded transition duration-200"
                >
                  cancel
                </button>
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };

  const renderSections = (sections: Section[]) => {
    const parentSections = sections.filter(
      (section) => section.parentId === null
    );
    console.log(parentSections);
    return parentSections.map((section) => (
      <div
        key={section.id}
        className=" mt-4 border-blue-300 border-2 rounded-md p-4  bg-gray-100 hover:shadow-lg hover:bg-gray-200 transition duration-300"
      >
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            {sections.some((s) => s.parentId === section.id) && (
              <button onClick={() => toggleExpand(section.id)} className="mr-2">
                {expandedSections[section.id] ? (
                  <MdOutlineKeyboardArrowUp size={20} className="text-black" />
                ) : (
                  <MdOutlineKeyboardArrowDown
                    size={20}
                    className="text-black"
                  />
                )}
              </button>
            )}
            <span className="text-lg font-semibold text-gray-700">
              {section.name}
            </span>
          </div>

          <div className="flex items-center space-x-2">
            <button
              onClick={() => setActiveSectionId(section.id)}
              className="text-blue-500 hover:text-blue-700 transition duration-200"
            >
              <MdAdd size={20} />
            </button>
            <button
              onClick={() => {
                setEditingSectionId(section.id);
                setInputValues({
                  name: section.name,
                  pageId: section.pageId || "",
                });
              }}
              className="text-yellow-500 hover:text-yellow-700 transition duration-200"
            >
              <MdEdit size={20} />
            </button>
          </div>
        </div>
        {expandedSections[section.id] &&
          renderSubSections(section.id, sections)}

        {activeSectionId === section.id && (
          <div className="ml-6 mt-2">
            <input
              type="text"
              placeholder="Section Name"
              onChange={(e) =>
                setInputValues((prev) => ({
                  ...prev,
                  name: e.target.value,
                }))
              }
              className="border border-gray-300 px-2 py-1 rounded focus:border-green-500 focus:ring-green-500"
            />
            {pages.length > 0 ? (
              <select
                onChange={(e) =>
                  setInputValues((prev) => ({
                    ...prev,
                    pageId: e.target.value,
                  }))
                }
                className="border border-gray-300 px-2 py-1 rounded sm:ml-2 focus:border-green-500 focus:ring-green-500"
              >
                <option value="">Select Page No</option>
                {pages.map((option: any, index: number) => (
                  <option key={index} value={option.id}>
                    {option.pageNo}
                  </option>
                ))}
              </select>
            ) : (
              <p>No pages found</p>
            )}

            <div className="mt-2">
              <button
                onClick={() => {
                  handleAddSections(
                    inputValues.name,
                    section.id,
                    inputValues.pageId
                  );
                  setActiveSectionId(null);
                  setInputValues({ name: "", pageId: "" });
                }}
                className="ml-2 px-3 py-1 bg-green-500 text-white rounded"
              >
                Add
              </button>
              <button
                onClick={() => setActiveSectionId(null)}
                className="ml-2 px-3 py-1 bg-gray-500 hover:bg-green-600 text-white rounded transition duration-200"
              >
                cancel
              </button>
            </div>
          </div>
        )}
        {/* Input fields for editing sections/subsections */}
        {editingSectionId === section.id && (
          <div className="ml-6 mt-2">
            <input
              type="text"
              value={inputValues.name}
              onChange={(e) =>
                setInputValues((prev) => ({
                  ...prev,
                  name: e.target.value,
                }))
              }
              className="border border-gray-300 px-2 py-1 rounded"
            />
            <select
              value={inputValues.pageId}
              onChange={(e) =>
                setInputValues((prev) => ({
                  ...prev,
                  pageId: e.target.value,
                }))
              }
              className="border border-gray-300 px-2 py-1 rounded sm:ml-2 focus:border-green-500 focus:ring-green-500"
            >
              <option value="">Select Page No</option>
              {pages.map((option: any, index: number) => (
                <option key={index} value={option.id}>
                  {option.pageNo}
                </option>
              ))}
            </select>

            {/* Save Changes Button */}
            <button
              onClick={() => {
                handleEditSection(
                  section.id, // Current section ID
                  selectedBook, // Book ID from your state
                  inputValues.name, // Updated name
                  section.parentId || null,  // Parent ID (null for root sections)
                  inputValues.pageId // Updated page ID
                );
                setEditingSectionId("");
                setInputValues({ name: "", pageId: "" });
              }}
              className="ml-2 px-3 py-1 bg-green-500 hover:bg-green-600 text-white rounded transition duration-200"
            >
              Save
            </button>
            <button
              onClick={() => setEditingSectionId("")}
              className="ml-2 px-3 py-1 bg-gray-500 hover:bg-green-600 text-white rounded transition duration-200"
            >
              cancel
            </button>
          </div>
        )}
      </div>
    ));
  };
  return (
    <div className="p-8 max-w-5xl mx-auto border rounded-lg shadow-md mt-9">
      <h2 className="text-3xl font-bold  text-blue-600 mb-6 text-center">
        Book Sections
      </h2>

      <div className="mb-6">
        <div className=" container px-4 mx-auto">
          <div className="mt-6">
            <label
              className="text-gray-900 dark:text-gray-200 font-semibold text-lg"
              htmlFor="book-select"
            >
              Select Book : <span className="text-red-500">*</span>
            </label>
            <select
              required
              value={selectedBook}
              id="book-select"
              onChange={handleBookChange}
              className="border border-gray-300  p-4 w-full rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200"
            >
              <option value="">-- Select Book -- </option>
              {booksData.map((option: any) => (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>

          {/* Show add section button only after book is selected */}
          {/* Show the section management UI only when a book is selected */}
          {selectedBook && (
            <div className="border-t pt-4 mt-6">
              <div>
                {/* Render the sections if available */}
                {filteredSections.length > 0 ? (
                  <div>{renderSections(filteredSections)}</div>
                ) : (
                  <div className="w-full ">
                    <p className=" flex flex-col items-center justify-center ">
                      No sections are available for this book
                    </p>
                  </div>
                )}

                {/* Always show the Add Section button regardless of the filteredSections */}
                <button
                  onClick={() => setAddingNewSection(true)}
                  className="mt-6 px-4 py-2 bg-blue-500 text-white rounded-lg shadow-sm hover:bg-blue-600 transition duration-200"
                >
                  Add Section
                </button>

                {/* Show the input fields to add a new section when addingNewSection is true */}

                {addingNewSection && (
                  <div className="mt-4 space-y-4">
                    <div className="flex items-center">
                      <input
                        type="text"
                        placeholder="Section Name"
                        onChange={(e) =>
                          setInputValues((prev) => ({
                            ...prev,
                            name: e.target.value,
                          }))
                        }
                        className="border border-gray-300 px-3 py-2 w-full rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                      />
                    </div>
                    <div className="flex items-center">
                      {pages.length > 0 ? (
                        <select
                          onChange={(e) =>
                            setInputValues((prev) => ({
                              ...prev,
                              pageId: e.target.value,
                            }))
                          }
                          className="border border-gray-300 px-3 py-2 w-full rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                        >
                          <option value="">Select Page No</option>
                          {pages.map((option: any, index: number) => (
                            <option key={index} value={option.id}>
                              {option.pageNo}
                            </option>
                          ))}
                        </select>
                      ) : (
                        <p>No pages found</p>
                      )}
                    </div>
                    <button
                      onClick={() => {
                        handleAddSections(
                          inputValues.name,
                          null,
                          inputValues.pageId
                        );
                        setInputValues({ name: "", pageId: "" });
                        setAddingNewSection(false);
                      }}
                      className="ml-2 px-3 py-1 bg-green-500 text-white rounded-lg shadow-sm hover:bg-green-600 transition duration-200"
                    >
                      Add
                    </button>
                    <button
                      onClick={() => setAddingNewSection(false)}
                      className="ml-2 px-3 py-1 bg-gray-500 text-white rounded-lg shadow-sm hover:bg-green-600 transition duration-200"
                    >
                      Cancel
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SectionPage;
